import { lighten, darken } from "polished";

import {
  primaryColor,
  secondaryColor,
  tertiaryColor,
  accentColor,
  white,
  black,
  grey,
  lightGrey,
} from "./colors";
import { fontFamilies, headlineFont, bodyFont } from "./fonts";

const headlineFontStyles: React.CSSProperties = {
  fontFamily: headlineFont,
  fontWeight: "normal",
  fontStyle: "normal",
  // letterSpacing: "-0.02em",
};

const commonColorStyles: { [key: string]: string } = {
  white,
  black,
  grey,
  lightGrey,
};

const customColorStyles: {
  tertiary: { light: string; main: string; dark: string };
  accent: { light: string; main: string; dark: string };
} = {
  tertiary: {
    light: lighten(0.1, tertiaryColor),
    main: tertiaryColor,
    dark: darken(0.1, tertiaryColor),
  },
  accent: {
    light: lighten(0.1, tertiaryColor),
    main: accentColor,
    dark: darken(0.1, tertiaryColor),
  },
};

export {
  primaryColor,
  secondaryColor,
  accentColor,
  fontFamilies,
  headlineFont,
  headlineFontStyles,
  commonColorStyles,
  bodyFont,
  customColorStyles,
};
